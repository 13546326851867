<script>
import { outletService } from "../../../helpers/backend/outlet.service"
export default {
  data() {
    return {
      form: {
        id: null,
        name: "",
        payment_gateway_name: "",
        server_key: "",
        client_key: "",
        merchant_id: "",
        store_id: "",
      },
      payment_gateway_options: ["Midtrans", "Shopee"],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      outletService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error = "Failed to Insert/Update Machine Type. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.name = ""
      this.form.payment_gateway_name = ""
      this.form.server_key = ""
      this.form.client_key = ""
      this.form.merchant_id = ""
      this.form.store_id = ""
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.name.$el.focus()
        this.busy = true
        outletService.getById(id).then(
          (data) => {
            if (data.outlet != null) {
              this.form.id = data.outlet.id
              this.form.name = data.outlet.name
              this.form.payment_gateway_name = data.outlet.payment_gateway_name
              this.form.server_key = ""
              this.form.client_key = ""
              this.form.merchant_id = data.outlet.merchant_id
              this.form.store_id = data.outlet.store_id
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get outlet type to update. Error : Advertisement Type not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get outlet type to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Outlet Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Outlet Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Payment Gateway:"
                label-for="input-2"
              >
                <b-form-select
                  id="input-2"
                  v-model="form.payment_gateway_name"
                  :options="payment_gateway_options"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Server Key:"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.server_key"
                  type="text"
                  placeholder="Server Key"
                  v-bind:required="form.id == null ? true : false"
                  ref="server_key"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label="Client Key:"
                label-for="input-4"
              >
                <b-form-input
                  id="input-4"
                  v-model="form.client_key"
                  type="text"
                  placeholder="Client Key"
                  v-bind:required="form.id == null ? true : false"
                  ref="client_key"
                ></b-form-input>
              </b-form-group>
              <template v-if="form.payment_gateway_name == 'Shopee'">
                <b-form-group
                  id="input-group-5"
                  label="Merchant ID:"
                  label-for="input-5"
                >
                  <b-form-input
                    id="input-5"
                    v-model="form.merchant_id"
                    type="text"
                    placeholder="Shopee Merchant ID"
                    required
                    ref="merchant_id"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-6"
                  label="Store ID:"
                  label-for="input-6"
                >
                  <b-form-input
                    id="input-6"
                    v-model="form.store_id"
                    type="text"
                    placeholder="Shopee Store ID"
                    required
                    ref="store_id"
                  ></b-form-input>
                </b-form-group>
              </template>
          
              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



